import React from 'react';

const IconLogo = () => (
  // <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
  //   <title>Logo</title>
  //   <g transform="translate(-8.000000, -2.000000)">
  //     <g transform="translate(11.000000, 5.000000)">
  //       <path
  //         // d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
  //         d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
  //         fill="currentColor"
  //       />
  //       {/* <path
  //         d="M 25 1.0507812 C 24.7825 1.0507812 24.565859 1.1197656 24.380859 1.2597656 L 1.3808594 19.210938 C 0.95085938 19.550938 0.8709375 20.179141 1.2109375 20.619141 C 1.5509375 21.049141 2.1791406 21.129062 2.6191406 20.789062 L 4 19.710938 L 4 46 C 4 46.55 4.45 47 5 47 L 19 47 L 19 29 L 31 29 L 31 47 L 45 47 C 45.55 47 46 46.55 46 46 L 46 19.710938 L 47.380859 20.789062 C 47.570859 20.929063 47.78 21 48 21 C 48.3 21 48.589063 20.869141 48.789062 20.619141 C 49.129063 20.179141 49.049141 19.550938 48.619141 19.210938 L 25.619141 1.2597656 C 25.434141 1.1197656 25.2175 1.0507812 25 1.0507812 z M 35 5 L 35 6.0507812 L 41 10.730469 L 41 5 L 35 5 z"
  //         fill="currentColor"
  //       /> */}
  //       <polygon
  //         id="Shape"
  //         stroke="currentColor"
  //         strokeWidth="5"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         points="39 0 0 22 0 67 39 90 78 68 78 23"
  //       />
  //     </g>
  //   </g>
  // </svg>

  // <svg xmlns="http://www.w3.org/2000/svg" width="64" height="94" fill="none" viewBox="0 0 64 94">
  //   <path
  //     fill="currentColor"
  //     d="M.273 94V.91h31.454C39.03.91 45 2.226 49.637 4.863 54.302 7.47 57.756 11 60 15.454c2.242 4.455 3.364 9.425 3.364 14.91 0 5.485-1.122 10.47-3.364 14.954-2.212 4.485-5.636 8.06-10.273 10.727C45.091 58.682 39.152 60 31.91 60H9.364V50h22.181c5 0 9.016-.864 12.046-2.59 3.03-1.728 5.227-4.061 6.59-7 1.395-2.97 2.092-6.32 2.092-10.046 0-3.728-.697-7.061-2.091-10-1.364-2.94-3.576-5.243-6.637-6.91-3.06-1.696-7.12-2.545-12.181-2.545H11.545V94H.274Z"
  //   />
  // </svg>

  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="900.000000pt"
    height="800.000000pt"
    viewBox="0 0 900.000000 800.000000"
    preserveAspectRatio="xMidYMid meet">
    <g
      transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none">
      <path
        d="M4415 6896 c-86 -28 -40 12 -965 -836 -183 -168 -421 -386 -530 -485
-108 -99 -298 -273 -421 -386 -260 -239 -304 -280 -668 -613 -314 -287 -325
-302 -326 -426 0 -96 21 -141 113 -238 90 -93 157 -127 257 -127 93 0 133 21
265 140 58 52 108 95 111 95 3 0 6 -606 6 -1348 0 -1337 0 -1348 20 -1394 26
-58 91 -124 152 -152 45 -21 53 -21 646 -21 l600 0 42 23 c66 35 110 79 140
140 l28 57 3 958 2 957 665 0 665 0 1 -927 c1 -511 3 -945 5 -966 7 -86 66
-172 148 -215 l51 -27 609 -3 c695 -3 677 -5 765 74 29 26 62 65 74 88 l22 41
3 1343 2 1342 78 -66 77 -66 63 5 c47 4 76 13 118 38 69 41 206 186 235 248
51 109 27 234 -60 320 -43 43 -357 320 -650 575 -70 60 -162 141 -206 180 -44
40 -150 132 -235 206 -85 74 -157 137 -160 140 -3 3 -90 80 -195 170 -104 91
-194 170 -200 176 -5 6 -95 85 -200 175 -104 90 -194 168 -200 175 -5 6 -95
85 -199 175 -104 90 -252 219 -330 287 -165 143 -227 178 -319 180 -34 1 -80
-5 -102 -12z"
      />
    </g>
  </svg>

  // <svg
  //   fill="#000000"
  //   xmlns="http://www.w3.org/2000/svg"
  //   viewBox="0 0 50 50"
  //   width="50px"
  //   height="50px">
  //   {' '}
  //   <title>Logo</title>
  //   <path d="M 25 1.0507812 C 24.7825 1.0507812 24.565859 1.1197656 24.380859 1.2597656 L 1.3808594 19.210938 C 0.95085938 19.550938 0.8709375 20.179141 1.2109375 20.619141 C 1.5509375 21.049141 2.1791406 21.129062 2.6191406 20.789062 L 4 19.710938 L 4 46 C 4 46.55 4.45 47 5 47 L 19 47 L 19 29 L 31 29 L 31 47 L 45 47 C 45.55 47 46 46.55 46 46 L 46 19.710938 L 47.380859 20.789062 C 47.570859 20.929063 47.78 21 48 21 C 48.3 21 48.589063 20.869141 48.789062 20.619141 C 49.129063 20.179141 49.049141 19.550938 48.619141 19.210938 L 25.619141 1.2597656 C 25.434141 1.1197656 25.2175 1.0507812 25 1.0507812 z M 35 5 L 35 6.0507812 L 41 10.730469 L 41 5 L 35 5 z" />
  // </svg>
);

export default IconLogo;
